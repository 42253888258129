import request from "@/utils/request";
import Export from '@/utils/export';
// 套餐列表 分页
export function getPackageList(data) {
  return request("post", "/apm/recharge/package/list", data);
}
// 套餐充值概况
export function getPackageOverView(data) {
  return request("post", "/apm/recharge/package/overview", data);
}

// 充值订单查询
export function getRechargeOrder(data) {
  return request("post", "/apm/recharge/package/order/page", data);
}
// 套餐列表 不分页
export function getPackageListByShopId(data) {
  return request("post", "/apm/recharge/package/listByShopId", data);
}

// 充值套餐统计
export function getRechargeStatistics(data) {
  return request("post", "/apm/recharge/package/order/rechargeStatistics", data);
}

// 充值订单导出
export function goExportRechargeOrder(data,name) {
  return Export("post", "/apm/recharge/package/order/toExcel", data,name);
}

// 处理小程序码并下载
export function goDealQrcode(data) {
  return request('post', '/apm/recharge/package/deal', data)
}

// 小程序码下载
export function goDownloadQrcode(data) {
  return request('get', '/apm/recharge/package/download', data)
}

// 小程序码进度条
export function getQrcodeProgress(data) {
  return request('get', '/apm/recharge/package/progress', data)
}