<!--充值订单管理-->
<template>
  <div id="orderManagement">
    <div class="table-search">
      <div style="display: flex">
        <div class="search-L">
          <el-input
            v-model="tableParams.kw"
            placeholder="请输入昵称 / 手机号搜索"
            @input="kwChange"
            clearable
            class="mr15"
          />
          <el-select
            v-model="tableParams.packageId"
            placeholder="请选择充值套餐"
            clearable
            @change="reload"
            class="mr15"
          >
            <el-option
              v-for="item in packageList"
              :key="item.packageId"
              :label="item.packageName"
              :value="item.packageId"
            >
            </el-option>
          </el-select>
        </div>
        <el-date-picker
          v-model="dateToDate"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="~"
          start-placeholder="交易时间"
          end-placeholder="交易时间"
          :picker-options="pickerOptions"
          @change="goSelectDate()"
          clearable
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goExport()">导出</el-button>
      </div>
    </div>
    <div class="money">
      <div class="item">
        <div class="t1">充值总人数</div>
        <div class="t2">{{ statisticsInfo.total }}</div>
      </div>
      <div class="item">
        <div class="t1">今日充值人数</div>
        <div class="t2">{{ statisticsInfo.today }}</div>
      </div>
      <div class="item">
        <div class="t1">7天充值人数</div>
        <div class="t2">{{ statisticsInfo.last7 }}</div>
      </div>
      <div class="item">
        <div class="t1">30天充值人数</div>
        <div class="t2">{{ statisticsInfo.last30 }}</div>
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="充值人" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.name }}|{{ scope.row.mobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值套餐" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="赠送金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.giveAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.paymentDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单号" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.orderId }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";

import {
  getPackageListByShopId,
  getRechargeOrder,
  getRechargeStatistics,goExportRechargeOrder
} from "@/api/recharge/packageSetting.js";
export default {
  name: "OrderManagement",
  data() {
    return {
      pickerOptions,
      tableParams: {
        kw: "",
        shopId: "",
        packageId: "",
        page: 1,
        size: 10,
        dateFrom: "",
        dateTo: "",
      },
      packageList: [],
      tableData: [],
      total: 0,
      currentPage: 1,
      dateToDate: [],
      statisticsInfo: {},
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getPackageListByShopId();
    this.getRechargeOrder();
    this.getRechargeStatistics();
  },
  methods: {
    // 导出
    goExport(){
      let params = {
        shopId: this.tableParams.shopId,
        kw: this.tableParams.kw,
        page: this.tableParams.page,
        size: this.tableParams.size,
        goodsId: this.tableParams.packageId,
        dateFrom: this.tableParams.dateFrom,
        dateTo: this.tableParams.dateTo,
      };
      let name = '充值订单'
      goExportRechargeOrder(params,name)
    },
    // 充值套餐统计
    getRechargeStatistics() {
      let params = {
        shopIdSet: [Number(this.tableParams.shopId)],
      };
      getRechargeStatistics(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.statisticsInfo = res.data;
        }
      });
    },
    // 套餐列表
    getPackageListByShopId() {
      let params = {
        shopId: this.tableParams.shopId,
      };
      getPackageListByShopId(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.packageList = res.data;
        }
      });
    },
    // 选择日期
    goSelectDate() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      } else {
        this.tableParams.dateFrom = "";
        this.tableParams.dateTo = "";
      }
      this.reload();
    },
    // 充值明细
    getRechargeOrder() {
      let params = {
        shopId: this.tableParams.shopId,
        kw: this.tableParams.kw,
        page: this.tableParams.page,
        size: this.tableParams.size,
        goodsId: this.tableParams.packageId,
        dateFrom: this.tableParams.dateFrom,
        dateTo: this.tableParams.dateTo,
      };
      getRechargeOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getRechargeOrder();
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getRechargeOrder();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
#orderManagement {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .search-L {
      display: flex;
    }
  }
  .money {
    color: #000;
    font-size: 14px;
    margin-bottom: 0.2rem;
    display: flex;
    .item {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
    .t1 {
      color: #666;
      font-size: 14px;
      margin-right: 10px;
    }
    .t2 {
      font-size: 18px;
      color: #333;
      font-weight: 500;
    }
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>